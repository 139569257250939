<style scoped>
@media (max-width: 1024px) {
  .c-sm {
    margin-top: 1rem;
    border-right: unset !important;
  }
  .c-sm-border {
    padding-top: 1rem;
    border-top: 1px solid #32394e !important;
  }
}
.c-bottom {
  position: absolute;
  right:    0;
  bottom:   0;
  margin-right: 12px;
}
.c-features {
  margin-top: 1rem;
  margin-left: 1rem;
  font-size: 1rem;
  color: white;
  text-align: left;
}
.c-feature-description {

}
.c-plan-badge {
  margin-top: -35px;
  margin-bottom: 9px;
  z-index: 1;
  cursor: pointer;
}
.c-plan-container {
  margin-top: -25px;
}
</style>
<script>

import {mapGetters} from "vuex";

export default {
  components: {

  },
  computed: {

  },
  methods: {
    async getAvailablePlans() {
      try {
        let response = await fetch(`https://api.cftools.cloud/app/v1/store/public/subscription/plans?family=omega`);
        if(response.ok) {
          let data = await response.json();
          this.plans = data.plans;
          this.capabilities = data.capabilities;
          this.ready = true;
        } else {
          this.error = true;
          throw new Error(`(${this.$vnode.componentOptions.tag}) Failed with API error ${response.status}=${response.statusText} (${response.url})`);
        }
      } catch (error) {
        console.log(`[ERROR] ${error}`);
      }
    },

  },
  created() {

  },
  async mounted() {
    await this.getAvailablePlans();
  },
  destroyed() {

  },
  data() {
    return {
      ready: false,
      error: false,
      plans: {},
      capabilities: {}
    }
  }
};
</script>

<template>
  <section class="section bg-white" id="pricing" v-if="ready">
    <div class="text-center mb-4">
      <div class="small-title">Subscription</div>
      <h4>{{$t('billing.subscription.title')}}</h4>
    </div>

    <div class="c-plan-container mt-1">
      <template>
        <div class="row justify-content-center">
          <div class="col-lg-9 col-sm-12 h-100">
            <div class="">
              <div class="row justify-content-center text-center">
                <div class="col-lg-3 col-sm-12 c-sm" v-if="plans.free">
                  <div class="card card-body shadow shadow-lg border border-light h-100">
                    <h3 class="mb-1">
                      {{$t('billing.subscription.plans.free.title')}}
                    </h3>
                    <h1 class="mb-1">
                      {{ new Intl.NumberFormat('de-DE', { style: 'currency', currency: 'EUR' }).format(plans.free) }}
                    </h1>
                    <h5 class="text-muted">
                      {{$t('billing.subscription.plans.period.monthly')}}
                    </h5>

                    <a class="btn btn-primary btn-lg text-uppercase mt-2 mb-2" href="https://auth.cftools.cloud/register">
                      <h5 class="mb-0">
                        {{$t('billing.subscription.prompt')}}
                      </h5>
                    </a>

                    <div class="c-features mb-auto">
                      <div class="row">
                        <div class="col-2 text-center">
                          <i class="fas fa-server"/>
                        </div>
                        <div class="col-8">
                          <span class="c-feature-description">
                            <template v-if="capabilities.free.servers === -1">
                              <i class="fal fa-infinity"/> {{$t('billing.subscription.plans.descriptors.servers')}}
                              <span class="small c-feature-description text-muted">
                                {{$t('billing.subscription.plans.descriptors.fairuse')}}
                              </span>
                            </template>
                            <template v-else-if="capabilities.free.servers === 0" class="text-muted">
                              {{$t('billing.subscription.plans.descriptors.limit_servers')}}
                            </template>
                            <template v-else>
                              {{ $n(capabilities.free.servers) }} {{$t('billing.subscription.plans.descriptors.servers')}}
                            </template>
                          </span>
                        </div>
                      </div>
                      <div class="row">
                        <div class="col-2 text-center">
                          <i class="far fa-ban"/>
                        </div>
                        <div class="col-8">
                          <span class="c-feature-description">
                            <template v-if="capabilities.free.banlists === -1">
                              <i class="fal fa-infinity"/> {{$t('billing.subscription.plans.descriptors.banlists')}}
                              <span class="small c-feature-description text-muted">
                                {{$t('billing.subscription.plans.descriptors.fairuse')}}
                              </span>
                            </template>
                            <template v-else-if="capabilities.free.banlists === 0" class="text-muted">
                              {{$t('billing.subscription.plans.descriptors.limit_banlists')}}
                            </template>
                            <template v-else>
                              {{ $n(capabilities.free.banlists) }} {{$t('billing.subscription.plans.descriptors.banlists')}}
                            </template>
                          </span>
                        </div>
                      </div>
                      <div class="row">
                        <div class="col-2 text-center">
                          <i class="fad fa-users"/>
                        </div>
                        <div class="col-8">
                          <span class="c-feature-description">
                            <template v-if="capabilities.free.teams === -1">
                              <i class="fal fa-infinity"/> {{$t('billing.subscription.plans.descriptors.teams')}}
                              <span class="small c-feature-description text-muted">
                                {{$t('billing.subscription.plans.descriptors.fairuse')}}
                              </span>
                            </template>
                            <template v-else-if="capabilities.free.teams === 0">
                              <span class="text-muted">
                                {{$t('billing.subscription.plans.descriptors.limit_teams')}}
                              </span>
                            </template>
                            <template v-else>
                              {{ $n(capabilities.free.teams) }} {{$t('billing.subscription.plans.descriptors.teams')}}
                            </template>
                          </span>
                        </div>
                      </div>
                      <!-- Feature Checks -->
                      <div class="mt-3"/>

                      <div class="row">
                        <div class="col-2 text-center">
                          <i class="far fa-check text-success"/>
                        </div>
                        <div class="col-8 ">
                          <span class="c-feature-description">
                            {{$t('billing.subscription.plans.features.rcon')}}
                          </span>
                        </div>
                      </div>

                      <div class="row">
                        <div class="col-2 text-center">
                          <i class="far fa-check text-muted"/>
                        </div>
                        <div class="col-8">
                          <span class="c-feature-description">
                            {{$t('billing.subscription.plans.features.scheduler')}}
                          </span>
                          <span class="small text-muted c-feature-description">
                            {{ $n(capabilities.free.jobs) }} {{$t('billing.subscription.plans.descriptors.limit_jobs')}}
                          </span>
                        </div>
                      </div>

                      <div class="row">
                        <div class="col-2 text-center">
                          <i class="far fa-check text-muted"/>
                        </div>
                        <div class="col-8">
                          <span class="c-feature-description">
                            {{$t('billing.subscription.plans.features.messenger')}}
                          </span>
                          <span class="small text-muted c-feature-description">
                            {{ $n(capabilities.free.messages) }} {{$t('billing.subscription.plans.descriptors.limit_messages')}}
                          </span>
                        </div>
                      </div>

                      <div class="row">
                        <div class="col-2 text-center">
                          <i class="far fa-check text-muted"/>
                        </div>
                        <div class="col-8">
                          <span class="c-feature-description">
                            {{$t('billing.subscription.plans.features.triggers')}}
                          </span>
                          <span class="small text-muted c-feature-description">
                            {{ $n(capabilities.free.triggers) }} {{$t('billing.subscription.plans.descriptors.limit_triggers')}}
                          </span>
                        </div>
                      </div>

                      <div class="row">
                        <div class="col-2 text-center">
                          <i class="far fa-check text-muted"/>
                        </div>
                        <div class="col-8">
                          <span class="c-feature-description">
                            {{$t('billing.subscription.plans.features.integrations')}}
                          </span>
                          <span class="small text-muted c-feature-description">
                            {{ $n(capabilities.free.webhooks) }} {{$t('billing.subscription.plans.descriptors.limit_webhooks')}}
                          </span>
                        </div>
                      </div>

                      <div class="row">
                        <div class="col-2 text-center">
                          <i class="far fa-times text-danger font-size-20"/>
                        </div>
                        <div class="col-8">
                          <span class="c-feature-description text-muted">
                            {{$t('billing.subscription.plans.features.hosting')}}
                          </span>
                        </div>
                      </div>

                      <div class="row">
                        <div class="col-2 text-center">
                          <i class="far fa-times text-danger font-size-20"/>
                        </div>
                        <div class="col-8">
                          <span class="c-feature-description text-muted">
                            {{$t('billing.subscription.plans.features.support')}}
                          </span>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>

                <div class="col-lg-3 col-sm-12 c-sm" v-if="plans.basic">
                  <div class="card card-body shadow shadow-lg border border-light h-100">
                    <h3 class="mb-1">
                      {{$t('billing.subscription.plans.basic.title')}}
                    </h3>
                    <h1 class="mb-1">
                      {{ new Intl.NumberFormat('de-DE', { style: 'currency', currency: 'EUR' }).format(plans.basic) }}
                    </h1>
                    <h5 class="text-muted">
                      {{$t('billing.subscription.plans.period.monthly')}}
                    </h5>

                    <a class="btn btn-primary btn-lg text-uppercase mt-2 mb-2" href="https://auth.cftools.cloud/register">
                      <h5 class="mb-0">
                        {{$t('billing.subscription.prompt')}}
                      </h5>
                    </a>

                    <div class="c-features mb-auto">
                      <div class="row">
                        <div class="col-2 text-center">
                          <i class="fas fa-server"/>
                        </div>
                        <div class="col-8">
                          <span class="c-feature-description">
                            <template v-if="capabilities.basic.servers === -1">
                              <i class="fal fa-infinity"/> {{$t('billing.subscription.plans.descriptors.servers')}}
                              <span class="small c-feature-description text-muted">
                                {{$t('billing.subscription.plans.descriptors.fairuse')}}
                              </span>
                            </template>
                            <template v-else-if="capabilities.basic.servers === 0" class="text-muted">
                              {{$t('billing.subscription.plans.descriptors.limit_servers')}}
                            </template>
                            <template v-else>
                              {{ $n(capabilities.basic.servers) }} {{$t('billing.subscription.plans.descriptors.servers')}}
                            </template>
                          </span>
                        </div>
                      </div>
                      <div class="row">
                        <div class="col-2 text-center">
                          <i class="far fa-ban"/>
                        </div>
                        <div class="col-8">
                          <span class="c-feature-description">
                            <template v-if="capabilities.basic.banlists === -1">
                              <i class="fal fa-infinity"/> {{$t('billing.subscription.plans.descriptors.banlists')}}
                              <span class="small c-feature-description text-muted">
                                {{$t('billing.subscription.plans.descriptors.fairuse')}}
                              </span>
                            </template>
                            <template v-else-if="capabilities.basic.banlists === 0" class="text-muted">
                              {{$t('billing.subscription.plans.descriptors.limit_banlists')}}
                            </template>
                            <template v-else>
                              {{ $n(capabilities.basic.banlists) }} {{$t('billing.subscription.plans.descriptors.banlists')}}
                            </template>
                          </span>
                        </div>
                      </div>
                      <div class="row">
                        <div class="col-2 text-center">
                          <i class="fad fa-users"/>
                        </div>
                        <div class="col-8">
                          <span class="c-feature-description">
                            <template v-if="capabilities.basic.teams === -1">
                              <i class="fal fa-infinity"/> {{$t('billing.subscription.plans.descriptors.teams')}}
                              <span class="small c-feature-description text-muted">
                                {{$t('billing.subscription.plans.descriptors.fairuse')}}
                              </span>
                            </template>
                            <template v-else-if="capabilities.basic.teams === 0">
                              <span class="text-muted">
                                {{$t('billing.subscription.plans.descriptors.limit_teams')}}
                              </span>
                            </template>
                            <template v-else>
                              {{ $n(capabilities.basic.teams) }} {{$t('billing.subscription.plans.descriptors.teams')}}
                            </template>
                          </span>
                        </div>
                      </div>
                      <!-- Feature Checks -->
                      <div class="mt-3"/>

                      <div class="row">
                        <div class="col-2 text-center">
                          <i class="far fa-check text-success"/>
                        </div>
                        <div class="col-8 ">
                          <span class="c-feature-description">
                            {{$t('billing.subscription.plans.features.rcon')}}
                          </span>
                        </div>
                      </div>

                      <div class="row">
                        <div class="col-2 text-center">
                          <i class="far fa-check text-success"/>
                        </div>
                        <div class="col-8">
                          <span class="c-feature-description">
                            {{$t('billing.subscription.plans.features.base')}}
                          </span>
                        </div>
                      </div>

                      <div class="row">
                        <div class="col-2 text-center">
                          <i class="far fa-check text-success"/>
                        </div>
                        <div class="col-8">
                          <span class="c-feature-description font-size-14">
                            {{$t('billing.subscription.plans.features.basic_combi')}}
                          </span>
                          <span class="small c-feature-description text-muted">
                            {{$t('billing.subscription.plans.descriptors.unlimited')}}
                          </span>
                        </div>
                      </div>

                      <div class="row">
                        <div class="col-2 text-center">
                          <i class="far fa-check text-success"/>
                        </div>
                        <div class="col-8">
                          <span class="c-feature-description">
                            {{$t('billing.subscription.plans.features.integrations')}}
                          </span>
                          <span class="small c-feature-description text-muted">
                            {{$t('billing.subscription.plans.descriptors.fairuse')}}
                          </span>
                        </div>
                      </div>

                      <div class="row">
                        <div class="col-2 text-center">
                          <i class="far fa-check text-success"/>
                        </div>
                        <div class="col-8">
                          <span class="c-feature-description">
                            {{$t('billing.subscription.plans.features.hosting')}}
                          </span>
                        </div>
                      </div>

                      <div class="row">
                        <div class="col-2 text-center">
                          <i class="far fa-check text-success"/>
                        </div>
                        <div class="col-8">
                          <span class="c-feature-description">
                            {{$t('billing.subscription.plans.features.support')}}
                          </span>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>

                <div class="col-lg-3 col-sm-12 c-sm" v-if="plans.pro">
                  <div class="card card-body shadow shadow-lg border border-light h-100">
                    <div class="text-center c-plan-badge float-none">
                      <span class="badge badge-primary">
                        <h5 class="mb-0">
                          {{$t('billing.subscription.plans.descriptors.popular')}}
                        </h5>
                      </span>
                    </div>
                    <h3 class="mb-1">
                      {{$t('billing.subscription.plans.pro.title')}}
                    </h3>
                    <h1 class="mb-1">
                      {{ new Intl.NumberFormat('de-DE', { style: 'currency', currency: 'EUR' }).format(plans.pro) }}
                    </h1>
                    <h5 class="text-muted">
                      {{$t('billing.subscription.plans.period.monthly')}}
                    </h5>

                    <a class="btn btn-primary btn-lg text-uppercase mt-2 mb-2" href="https://auth.cftools.cloud/register">
                      <h5 class="mb-0">
                        {{$t('billing.subscription.prompt')}}
                      </h5>
                    </a>

                    <div class="c-features mb-auto">
                      <div class="row">
                        <div class="col-2 text-center">
                          <i class="fas fa-server"/>
                        </div>
                        <div class="col-8">
                          <span class="c-feature-description">
                            <template v-if="capabilities.pro.servers === -1">
                              <i class="fal fa-infinity"/> {{$t('billing.subscription.plans.descriptors.servers')}}
                              <span class="small c-feature-description text-muted">
                                {{$t('billing.subscription.plans.descriptors.fairuse')}}
                              </span>
                            </template>
                            <template v-else-if="capabilities.pro.servers === 0" class="text-muted">
                              {{$t('billing.subscription.plans.descriptors.limit_servers')}}
                            </template>
                            <template v-else>
                              {{ $n(capabilities.pro.servers) }} {{$t('billing.subscription.plans.descriptors.servers')}}
                            </template>
                          </span>
                        </div>
                      </div>
                      <div class="row">
                        <div class="col-2 text-center">
                          <i class="far fa-ban"/>
                        </div>
                        <div class="col-8">
                          <span class="c-feature-description">
                            <template v-if="capabilities.pro.banlists === -1">
                              <i class="fal fa-infinity"/> {{$t('billing.subscription.plans.descriptors.banlists')}}
                              <span class="small c-feature-description text-muted">
                                {{$t('billing.subscription.plans.descriptors.fairuse')}}
                              </span>
                            </template>
                            <template v-else-if="capabilities.pro.banlists === 0" class="text-muted">
                              {{$t('billing.subscription.plans.descriptors.limit_banlists')}}
                            </template>
                            <template v-else>
                              {{ $n(capabilities.pro.banlists) }} {{$t('billing.subscription.plans.descriptors.banlists')}}
                            </template>
                          </span>
                        </div>
                      </div>
                      <div class="row">
                        <div class="col-2 text-center">
                          <i class="fad fa-users"/>
                        </div>
                        <div class="col-8">
                          <span class="c-feature-description">
                            <template v-if="capabilities.pro.teams === -1">
                              <i class="fal fa-infinity"/> {{$t('billing.subscription.plans.descriptors.teams')}}
                              <span class="small c-feature-description text-muted">
                                {{$t('billing.subscription.plans.descriptors.fairuse')}}
                              </span>
                            </template>
                            <template v-else-if="capabilities.pro.teams === 0">
                              <span class="text-muted">
                                {{$t('billing.subscription.plans.descriptors.limit_teams')}}
                              </span>
                            </template>
                            <template v-else>
                              {{ $n(capabilities.pro.teams) }} {{$t('billing.subscription.plans.descriptors.teams')}}
                            </template>
                          </span>
                        </div>
                      </div>

                      <!-- Feature Checks -->
                      <div class="mt-3"/>

                      <div class="row">
                        <div class="col-12 text-left ">
                          <span class="badge badge-info c-feature-description text-black w-100">
                            {{$t('billing.subscription.plans.descriptors.carryover_basic')}}
                          </span>
                        </div>
                      </div>

                      <div class="row">
                        <div class="col-2 text-center">
                          <i class="far fa-check text-success"/>
                        </div>
                        <div class="col-8">
                          <span class="c-feature-description">
                            {{$t('billing.subscription.plans.features.dedicated')}}
                          </span>
                        </div>
                      </div>
                      <!--
                      <div class="row">
                        <div class="col-2 text-center">
                          <i class="far fa-check text-success"/>
                        </div>
                        <div class="col-8">
                          <span class="c-feature-description">
                            {{$t('billing.subscription.plans.features.triggers_advanced')}}
                          </span>
                        </div>
                      </div>
                      -->
                      <div class="row">
                        <div class="col-2 text-center">
                          <i class="far fa-check text-success"/>
                        </div>
                        <div class="col-8">
                          <span class="c-feature-description">
                            {{$t('billing.subscription.plans.features.logs')}}
                          </span>
                        </div>
                      </div>

                      <div class="row">
                        <div class="col-2 text-center">
                          <i class="far fa-check text-success"/>
                        </div>
                        <div class="col-8">
                          <span class="c-feature-description">
                            {{$t('billing.subscription.plans.features.privacy')}}
                          </span>
                        </div>
                      </div>

                      <div class="row">
                        <div class="col-2 text-center">
                          <i class="far fa-check text-success"/>
                        </div>
                        <div class="col-8">
                          <span class="c-feature-description">
                            {{$t('billing.subscription.plans.features.watchers')}}
                          </span>
                        </div>
                      </div>

	                    <div class="row">
		                    <div class="col-2 text-center">
			                    <i class="far fa-check text-success"/>
		                    </div>
		                    <div class="col-8">
                          <span class="c-feature-description">
                            {{$t('billing.subscription.plans.features.radar')}}
	                          <span class="badge badge-primary small">
		                          {{$t('billing.subscription.plans.features.soon')}}
	                          </span>
                          </span>
		                    </div>
	                    </div>

                    </div>
                  </div>
                </div>
                <div class="col-lg-3 col-sm-12 c-sm" v-if="plans.community">
                  <div class="card card-body shadow shadow-lg border border-light h-100">
                    <h3 class="mb-1">
                      {{$t('billing.subscription.plans.community.title')}}
                    </h3>
                    <h1 class="mb-1">
                      {{ new Intl.NumberFormat('de-DE', { style: 'currency', currency: 'EUR' }).format(plans.community) }}
                    </h1>
                    <h5 class="text-muted">
                      {{$t('billing.subscription.plans.period.monthly')}}
                    </h5>

                    <a class="btn btn-primary btn-lg text-uppercase mt-2 mb-2" href="https://auth.cftools.cloud/register">
                      <h5 class="mb-0">
                        {{$t('billing.subscription.prompt')}}
                      </h5>
                    </a>

                    <div class="c-features mb-auto">
                      <div class="row">
                        <div class="col-2 text-center">
                          <i class="fas fa-server"/>
                        </div>
                        <div class="col-8">
                          <span class="c-feature-description">
                            <template v-if="capabilities.community.servers === -1">
                              <i class="fal fa-infinity"/> {{$t('billing.subscription.plans.descriptors.servers')}}
                              <span class="small c-feature-description text-muted">
                                {{$t('billing.subscription.plans.descriptors.fairuse')}}
                              </span>
                            </template>
                            <template v-else-if="capabilities.community.servers === 0" class="text-muted">
                              {{$t('billing.subscription.plans.descriptors.limit_servers')}}
                            </template>
                            <template v-else>
                              {{ $n(capabilities.community.servers) }} {{$t('billing.subscription.plans.descriptors.servers')}}
                            </template>
                          </span>
                        </div>
                      </div>
                      <div class="row">
                        <div class="col-2 text-center">
                          <i class="far fa-ban"/>
                        </div>
                        <div class="col-8">
                          <span class="c-feature-description">
                            <template v-if="capabilities.community.banlists === -1">
                              <i class="fal fa-infinity"/> {{$t('billing.subscription.plans.descriptors.banlists')}}
                              <span class="small c-feature-description text-muted">
                                {{$t('billing.subscription.plans.descriptors.fairuse')}}
                              </span>
                            </template>
                            <template v-else-if="capabilities.community.banlists === 0" class="text-muted">
                              {{$t('billing.subscription.plans.descriptors.limit_banlists')}}
                            </template>
                            <template v-else>
                              {{ $n(capabilities.community.banlists) }} {{$t('billing.subscription.plans.descriptors.banlists')}}
                            </template>
                          </span>
                        </div>
                      </div>
                      <div class="row">
                        <div class="col-2 text-center">
                          <i class="fad fa-users"/>
                        </div>
                        <div class="col-8">
                          <span class="c-feature-description">
                            <template v-if="capabilities.community.teams === -1">
                              <i class="fal fa-infinity"/> {{$t('billing.subscription.plans.descriptors.teams')}}
                              <span class="small c-feature-description text-muted">
                                {{$t('billing.subscription.plans.descriptors.fairuse')}}
                              </span>
                            </template>
                            <template v-else-if="capabilities.community.teams === 0">
                              <span class="text-muted">
                                {{$t('billing.subscription.plans.descriptors.limit_teams')}}
                              </span>
                            </template>
                            <template v-else>
                              {{ $n(capabilities.community.teams) }} {{$t('billing.subscription.plans.descriptors.teams')}}
                            </template>
                          </span>
                        </div>
                      </div>

                      <!-- Feature Checks -->
                      <div class="mt-3"/>

                      <div class="row">
                        <div class="col-12 text-left ">
                          <span class="badge badge-info c-feature-description text-black w-100">
                            {{$t('billing.subscription.plans.descriptors.carryover_pro')}}
                          </span>
                        </div>
                      </div>

                      <div class="row">
                        <div class="col-2 text-center">
                          <i class="far fa-check text-success"/>
                        </div>
                        <div class="col-8">
                          <span class="c-feature-description">
                            {{$t('billing.subscription.plans.features.support_priority')}}
                          </span>
                        </div>
                      </div>

	                    <div class="row">
		                    <div class="col-2 text-center">
			                    <i class="far fa-check text-success"/>
		                    </div>
		                    <div class="col-8">
                          <span class="c-feature-description">
                            {{$t('billing.subscription.plans.features.early_access')}}
                          </span>
		                    </div>
	                    </div>

	                    <div class="row">
		                    <div class="col-2 text-center">
			                    <i class="far fa-check text-success"/>
		                    </div>
		                    <div class="col-8">
                          <span class="c-feature-description">
                            {{$t('billing.subscription.plans.features.global_player_intelligence')}}
                          </span>
		                    </div>
	                    </div>

	                    <div class="row">
		                    <div class="col-2 text-center">
			                    <i class="far fa-check text-success"/>
		                    </div>
		                    <div class="col-8">
                          <span class="c-feature-description">
                            {{$t('billing.subscription.plans.features.advanced_radar')}}
	                          <span class="badge badge-primary small">
		                          {{$t('billing.subscription.plans.features.soon')}}
	                          </span>
                          </span>
		                    </div>
	                    </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
        <div class="text-center"><small>Existing game server required, no game server rental is provided</small></div>
      </template>
    </div>
  </section>
</template>
